/* Dashboard Page Styles */
.dashboard-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: #F5FAFF;
  }
  
  .main-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 250px;
  }
  
  .content {
    flex: 1;
    display: flex;
    padding: 20px;
    padding-left: 80px;
  }

  .horizontal-rule {
    border: 1px solid #F0F0F0;
    margin: '20px 0'
   
  }

  .left-side {
    height: 350px;
    padding: 20px;
    width: 230px;
    background-color: #fff;
  }
  
  .add-business-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .tabs {
    margin-top: 20px;
  }
  
  .tab {
    padding: 10px 0;
    cursor: pointer;
  }
  
  .right-columns {
    flex: 1;
    padding: 20px;
    width: 500px;
  }
  
  .business-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .business-table th,
  .business-table td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .tabs button {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #0F5796;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
  }

  .tabs img {
    width: 21px;
    height: 21px;
  }

  /* BusinessManagementHeader.css */

.business-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  
}

.header-content {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  
}

.subtitle {
  font-size: 14px;
  color: #666;
}

.all-button {
  display: flex;
    align-items: center;
    width: 130px;
    height: 60px;
    gap: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #F0F0F0;
    color: #0F5796;
    cursor: pointer;
    margin-top: 20px;
}

.all-button img {
  width: 21px;
  height: 21px;
}

/* AddBusinessForm.css */

.add-business-form {

  margin: 20px auto;
  padding: 20px;
  
  border-radius: 5px;
}

h2 {
  margin-bottom: 20px;
}

.add-form-group {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-form-group label {
  display: block;
  
}

.add-form-group input {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-save-button, .add-save-button:hover {
  padding: 10px 20px;
  background-color: #0F5796;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-left: 18%;
  cursor: pointer;
}

