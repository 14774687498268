.pagination-container {
    display: flex;
    margin-left: 40px;
    margin-right: 40px;
    align-items: center;
    justify-content: space-between;
    margin-top: 20;
    list-style-type: none; }
  
  .button {
    border-color:#D1D5DB;
    background-color: white;
    margin: 5px;
    border-radius: 5px;
    padding: 10px;
  }

  /* Pagination.css */

.pagination, .pagination:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: #f0f0f0;
}

.icon {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  cursor: pointer;
}

.page-number {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.current-page {
  background-color: #fff;
}

  
    .pagination-item {
      padding: 0 12px;
      height: 32px;
      text-align: center;
      margin: auto 4px;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      border-radius: 16px;
      line-height: 1.43;
      font-size: 13px;
      min-width: 32px; }
  
      .dots:hover {
        background-color: transparent;
        cursor: default;
      }
      :hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
  
      .selected {
        background-color: rgba(0, 0, 0, 0.08);
      }

        .before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: '';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid rgba(0, 0, 0, 0.87);
          border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }
  
        .left {
          transform: rotate(-135deg) translate(-50%);
        }
  
        .right {
          transform: rotate(45deg);
        }
      
  
      .disabled {
        pointer-events: none;
      }
  
        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }
  
        :hover {
          background-color: transparent;
          cursor: default;
        }
      
    
  