body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .landing-page {
    text-align: center;
    padding: 0px;
  }
  
  header, header:hover {
    margin-bottom: 20px;
    padding: 10%;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: start;
    background-color: #F5FAFF;
  }

  .section-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 10%;
    padding-top: 5%;
  }

  .img-right {
      width: 100%;
  }

  .img-check {
    width: 96px;
    height: 96px;
}
  
  .cta {
    flex: 1;
    max-width: 50%;
    text-align: left;
  }
  
  .cta h2 {
    font-size: 60px;
    margin-bottom: 10px;
  }
  
  .image-container {
    flex: 1;
    max-width: 50%;
    text-align: right;
  }
  
  h1 {
    font-size: 40px;
    margin-bottom: 10px;
    color: #0F5796;
  }

  .btn-signup, .btn-signup:hover {
    background-color: #0F5796;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    margin-right: 30px;
    font-size: 16px;
    cursor: pointer;
  }

  .btn-login {
    background-color: white;
    border: 1px solid #0F5796;
    color: #0F5796;
    padding: 10px 20px;
    border-radius: 5px;
    margin-right: 30px;
    margin-top: 20px;
    font-size: 16px;
    cursor: pointer;
  }

  .btn-subscribe, .btn-subscribe:hover {
    background-color: white;
    border: 1px solid #0F5796;
    color: #0F5796;
    padding: 20px 40px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 16px;
    cursor: pointer;
  }

  .btn, .btn:hover {
    margin-right: 0px;
    background-color: #0F5796;
    color: #ffffff;
  }

  .second-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px; 
    margin-bottom: 120px;
    margin-left: 15%;
    margin-right: 15%;
  }
  
  .section-header {
    text-align: center;
    margin-bottom: 20px;
  }

  .section-header h2 {
    font-size: 48px;
    margin-bottom: 10px;
  }
  
  .home-card {
    flex: 1;
    max-width: 30%;
    padding: 20px;
    text-align: center;
  }
  
  .home-card h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .home-card p {
    font-size: 16px;
  }
  
  .card-image {
    margin-top: 20px;
  }

  .third-section, .third-section:hover {
    background-color: #F5FAFF;
    padding: 40px;
    margin-bottom: 10%;
  }
  
  .section-content {
    display: flex;
    justify-content: space-between;
    
  }

  .price {
    color: #ffffff;
    font-size: 64px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .plan-title {
    color: #ffffff;
    font-size: 32px;
    margin-bottom: 5px;
  }
  
  .general-info {
    flex: 1;
    max-width: 38%;
  }
  
  .general-info h2 {
    font-size: 60px;
    margin-bottom: 10px;
  }
  
  .general-info p {
    font-size: 16px;
  }
  
  .subscription-plans {
    flex: 1;
    max-width: 50%;
    display: flex;
    flex-direction: row;
  }

  .blue-plan, .blue-plan:hover {
    background-color: #0F5796;
  }

  .white-plan {
    color: #0F5796;
  }

  .list-icon {
    width: 20px;
    height: 20px;
  }
  
  .subscription-plan {
    margin-bottom: 20px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
  }

  .subscription-plan-overlay {
    position: relative;
    width: calc(45% - 40px); /* Adjust the width of the subscription plans */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
    
    padding: 20px;
    border-radius: 15px;
  }

  .description {
    margin-bottom: 0px;
  }
  
  .description ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .description li {
    position: relative;
    padding-left: 20px;
  }
  
  .checklist-icon {
    position: absolute;
    left: 0;
  }

  .subscription-plan-top {
    bottom: 40%; /* Position the first subscription plan at the top */
    right: 40px;
  }
  
  .subscription-plan-bottom {
    top: 20% /* Position the second subscription plan at the bottom */
  }
  
  .subscription-plan h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .subscription-plan p {
    font-size: 16px;
  }
  
  .subscription-plan button {
    background-color: green;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  p {
    font-size: 18px;
    margin-bottom: 10px;
    color: #798196;
  }

  li {
    font-size: 18px;
    margin-bottom: 10px;
    color: white;
  }

  .desc {
    font-size: 18px;
    margin-bottom: 10px;
    color: #0F5796;
  }
  
  section {
    margin: 40px 0;
  }
  
  h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  footer, footer:hover {
    background-color: #F5FAFF;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .footer-left {
    display: flex;
    align-items: center;
  }
  
  .footer-left h1 {
    margin-right: 10px;
    margin-bottom: 25px;
  }
  
  .footer-right a {
    color: #0F5796;
    margin-left: 15px;
    text-decoration: none;
  }

  .scroll-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .scroll-icon img {
    width: 56px; 
    height: 56px; 
    margin-bottom: 20px;
  }
  